<div class="expanding-row" data-cy="expanding-row" *ngIf="files" appClickOutside
     (rightClickOutside)="resetContextMenu()">

    <div class="breadcrumb-row" *ngIf="!isRecentMode && folder && (repository === 'personal' || repository === 'shared')">
      <span class="breadcrumb-row-item" [class.not-clickable]="folder?.Name === ''" (click)="folderClick(-1)">/{{ user ? user.uid : repository }}</span>
      <span class="breadcrumb-row-item" *ngFor="let folderName of folder?.Folder; let i = index" (click)="folderClick(i)">/{{ folderName }}</span>
      <span class="breadcrumb-row-item not-clickable" *ngIf="folder.Name !== ''">/{{ folder.Name }}</span>
    </div>

  @if(files.length > 0) {
    <cdk-virtual-scroll-viewport itemSize="30px" class="file-list" (scroll)="resetContextMenu()" >
      <app-files-list-file
        *cdkVirtualFor="let file of files; let i = index; trackBy: trackByFn"
        [file]="file"
        [style.animation-delay]="i * .03 +'s'"
        (click)="openFile(file)"
        (contextmenu)="onContextMenu(file, i, $event)"
        (contextMenuClick)="onContextMenu($event.file, i, $event.event)"
        [attr.data-cy]="file.Name.toLowerCase().replace(removeSpaceRegex, '-')"
        [class.disableHover]="isAdmin && file.FileType !== 'folder'"
        [allFilesJobTypes] = allFilesJobTypes
        [repository]="repository"
        [isRecentMode]="isRecentMode"
        [showRowContextMenu]="showRowContextMenu"
        [searchPhrase]="searchPhrase$ | async"
        [id]="file.Name + '-' + i"
      >
      </app-files-list-file>
    </cdk-virtual-scroll-viewport>
    <app-file-context-menu
      [(contextMenuRequired)]="contextMenuRequired"
      [isRecentMode]="isRecentMode"
      (openFile)="openFile($event)"
      data-cy="file-context-menu"
    ></app-file-context-menu>
  } @else {
    <div  class="placeholder">
      No files found in this folder.
    </div>
  }


</div>
