import { createReducer, on } from '@ngrx/store';
import { FileManagerModuleReducerHelper, recentFilesStateInitialState } from './file-manager.reducer.helper';
import { IFile, IRecentDto } from '@dunefront/common/dto/file.dto';
import {
  clearFileOperationFailure,
  clearRecentFilesFailure,
  clearRecentFilesSuccess,
  clearSelectUser,
  copyFileReturnFile,
  copyFileReturnList,
  deleteFileOrFolder,
  deleteUserSuccess,
  fileOperationFailure,
  loadAllFilesAndFoldersFailure,
  loadAllFilesAndFoldersSuccess,
  loadOrganizationUsersSuccess,
  loadRecentFilesFailure,
  loadRecentFilesSuccess,
  moveFileOrFolder,
  newFolder,
  restoreFile,
  selectUser,
  updateSearchPhrase,
} from './file-manager.actions';
import { AllRootFolders } from '@dunefront/common/modules/file-manager/file-manager.actions';
import { AppError } from '@dunefront/common/exceptions/IAppError';
import { PackProProfileErrorData } from '@dunefront/common/exceptions/file.errors';
import { IUserProfile } from '@dunefront/common/modules/auth/auth.interfaces';

export const FileManagerModuleStateFeatureName = 'fileManager';

export interface FileManagerModuleState {
  loading: boolean;
  allFilesAndFolders: AllFilesAndFoldersState;
  fileOperationErrorState: FileOperationErrorState;
  recentFilesState: RecentFilesState;
  organizationUsers: IUserProfile[];
  selectedUser: IUserProfile | null;
  searchPhrase: string;
}

export interface AllFilesAndFoldersState {
  rootFolders?: AllRootFolders;
  error?: Error;
}

export interface FileOperationErrorState {
  error?: AppError<PackProProfileErrorData>;
}

export interface FileOperationState {
  file?: IFile;
}

export interface RecentFilesState {
  recent: IRecentDto;
  getError?: Error;
  clearError?: Error;
}

export const fileManagerInitialState: FileManagerModuleState = {
  loading: false,
  allFilesAndFolders: {},
  fileOperationErrorState: {},
  recentFilesState: recentFilesStateInitialState,
  organizationUsers: [],
  selectedUser: null,
  searchPhrase: '',
};

export const fileManagerModuleReducer = createReducer<FileManagerModuleState>(
  fileManagerInitialState,
  on(loadAllFilesAndFoldersSuccess, (state, action) =>
    FileManagerModuleReducerHelper.onLoadAllFilesAndFoldersSuccess(state, action.allFilesAndFoldersActionResponse),
  ),
  on(loadAllFilesAndFoldersFailure, (state, action) => FileManagerModuleReducerHelper.onLoadAllFilesAndFoldersFailure(state, action.info)),
  on(fileOperationFailure, (state, action) => FileManagerModuleReducerHelper.onFileOperationFailure(state, action.error)),
  on(clearFileOperationFailure, (state) => FileManagerModuleReducerHelper.onClearFileOperationFailure(state)),
  on(loadRecentFilesSuccess, (state, action) =>
    FileManagerModuleReducerHelper.onLoadRecentFilesSuccess(state, action.recentFilesActionResponse),
  ),
  on(loadRecentFilesFailure, (state, action) => FileManagerModuleReducerHelper.onLoadRecentFilesFailure(state, action.info)),
  on(clearRecentFilesSuccess, (state) => FileManagerModuleReducerHelper.onClearRecentFilesSuccess(state)),
  on(clearRecentFilesFailure, (state, action) => FileManagerModuleReducerHelper.onClearRecentFilesFailure(state, action.error)),
  on(deleteFileOrFolder, moveFileOrFolder, copyFileReturnFile, copyFileReturnList, newFolder, restoreFile, (state) =>
    FileManagerModuleReducerHelper.clearOperationFailure(state),
  ),
  on(loadOrganizationUsersSuccess, (state, action) =>
    FileManagerModuleReducerHelper.onLoadUsersWithFoldersSuccess(state, action.organizationUsers),
  ),

  on(selectUser, (state, action) => FileManagerModuleReducerHelper.onSelectUser(state, action.id)),
  on(clearSelectUser, (state) => FileManagerModuleReducerHelper.onClearSelectUser(state)),
  on(deleteUserSuccess, (state, action): FileManagerModuleState => FileManagerModuleReducerHelper.onUserDeleted(state, action.user)),
  on(updateSearchPhrase, (state, action): FileManagerModuleState => ({ ...state, searchPhrase: action.searchPhrase })),
);
