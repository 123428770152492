import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { APP_CODE } from '@dunefront/common/common/app-target-config';
import { NgSwitch, NgSwitchCase, AsyncPipe, NgIf } from '@angular/common';
import { AppTargetConfig } from '../../../services/app-target-config';
import { Store } from '@ngrx/store';
import { getCurrentAppCodeBySelectedAppModule } from '../../../../+store/ui/ui.selectors';
import { isElectron } from '@dunefront/common/common/electron/is-electron';

@Component({
  selector: 'app-left-nav-app-logo',
  templateUrl: 'left-nav-app-logo.component.html',
  styleUrls: ['./left-nav-app-logo.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgSwitchCase, NgSwitch, AsyncPipe, NgIf]
})
export class LeftNavAppLogoComponent {
  public $currentAppCode = this.store.select(getCurrentAppCodeBySelectedAppModule);

  constructor(
    protected appConfig: AppTargetConfig,
    protected store: Store,
  ) {}

  @Input() public isPinned = false;
  @Input() public isHomepage = false;

  public appCode(moduleAppCode: APP_CODE | null): APP_CODE | null {
    return moduleAppCode ?? (isElectron() ? this.appConfig.appCode : null);
  }
}
