<app-page-panel header="Test Configuration">
  <div class="grid-form-container cols-4" *ngIf="selectedFluidData && temperatureSelect && selectedFluidData.fluid">
    <label>Test Type</label>
    <app-radio-group
      [source]="selectedFluidData.fluid"
      [key]="'RheometerTestType'"
      [items]="testTypeRadio"
      class="col-span-4"
      dataCy="rheometer-test-type-select"
      id="is_gravel_settling"
      (valueChanged)="emitFluidValue($event)"
    >
    </app-radio-group>

    <label>Temperature</label>

      <app-select
        *ngIf="selectedFluidData.rheometer.rowData"
        [items]="temperatureSelect"
        [value]="selectedFluidData.rheometer.rowData.Id"
        dataCy="rheometer-temperature-select"
        [isUiLockable]="false"
        [decimalPlaces]="2"
        (primitiveValueChanged)="onSelectedTemperatureChange($event.value)"
        [unitLabel]="temperatureSelectLabel"
      >
      </app-select>

    <label>Rotor-Bob Combination</label>
      <app-select
        [source]="selectedFluidData.rheometer.rowData"
        [key]="'RotorBobCombination'"
        [items]="rotorBobSelect"
        class="margin-right"
        [disabled]="selectedFluidData.fluid.RheometerTestType === RheometerTestType.Shear_Rate_Viscosity"
        (valueChanged)="emitRheometerValue($event)"
        [showSymbol]="false"
      >
      </app-select>
      <app-form-input [disabled]="true" [readOnlyValue]="rotorBobValue" [decimalPlaces]="3" [width]="120" [unitType]="UnitType.None" [showSymbol]="false">
      </app-form-input>


    <label>Speed Type</label>
    <app-select
      [source]="selectedFluidData.rheometer.rowData"
      [key]="'SpeedType'"
      [items]="speedTypeSelect"
      dataCy="rheometer-speed-type-select"
      [disabled]="selectedFluidData.fluid.RheometerTestType === RheometerTestType.Shear_Rate_Viscosity"
      (valueChanged)="emitRheometerValue($event)"
    >
    </app-select>

    <label>Torsion Spring Assembly</label>

      <app-select
        [source]="selectedFluidData.rheometer.rowData"
        [key]="'TorsionSpringAssembly'"
        [items]="torsionSpringSelect"
        [disableSorting]="true"
        [disabled]="selectedFluidData.fluid.RheometerTestType === RheometerTestType.Shear_Rate_Viscosity"
        class="margin-right"
        (valueChanged)="emitRheometerValue($event)"
        [showSymbol]="false"
      >
      </app-select>

      <app-form-input [disabled]="true" [readOnlyValue]="torsionSpringValue" [decimalPlaces]="3" [width]="120" [unitType]="UnitType.None" [showSymbol]="false">
      </app-form-input>
  </div>
</app-page-panel>
