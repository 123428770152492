import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FileManagerModuleState, FileManagerModuleStateFeatureName } from './file-manager.reducer';
import { getRouterSelectors, RouterReducerState } from '@ngrx/router-store';
import { FileUrlHelper } from '../../pages/home/file-url.helper';
import { IFile } from '@dunefront/common/dto/file.dto';
import { FileManagerHelper } from './file-manager.helper';
import { toSelectItem } from '@dunefront/common/common/select.helpers';
import { IUserProfile } from '@dunefront/common/modules/auth/auth.interfaces';

export const selectRouterState = createFeatureSelector<RouterReducerState>('router');
const { selectUrl, selectRouteParams } = getRouterSelectors(selectRouterState);
export const getFileManagerState = createFeatureSelector<FileManagerModuleState>(FileManagerModuleStateFeatureName);
export const getRepositoryFolderState = createSelector(
  getFileManagerState,
  selectUrl,
  (state: FileManagerModuleState, url: string): IFile | undefined => {
    const repository = FileUrlHelper.getFileRepositoryFromUrl(url);

    switch (repository) {
      case 'shared':
        return state.allFilesAndFolders.rootFolders?.sharedRootFolder;
      case 'demo':
        return state.allFilesAndFolders.rootFolders?.demoRootFolder;
      case 'trash':
        return state.allFilesAndFolders.rootFolders?.trashRootFolder;
      case 'backup':
        return state.allFilesAndFolders.rootFolders?.backupRootFolder;
      default:
        return state.allFilesAndFolders.rootFolders?.personalRootFolder;
    }
  },
);

export const getSearchPhrase = createSelector(getFileManagerState, (state) => state.searchPhrase);

export const getCurrentFolderState = createSelector(
  getRepositoryFolderState,
  selectRouteParams,
  (repositoryFolderState: IFile | undefined, { path }): IFile | undefined => {
    const pathArray = path ? FileUrlHelper.getFolderPathFromUrlParam(path) : [];
    return (pathArray.length && FileManagerHelper.findFolder(pathArray, repositoryFolderState)) || repositoryFolderState;
  },
);

export const getCurrentFolderStateFiltered = createSelector(
  getRepositoryFolderState,
  getCurrentFolderState,
  getSearchPhrase,
  (repoState, currentFolderState, searchPhrase) => {
    if (!repoState || !currentFolderState) {
      return undefined;
    }
    if (searchPhrase?.trim()?.length > 0) {
      const children = FileManagerHelper.getAllChildren([...(repoState.Children || [])]);
      return { ...repoState, Children: filterFilesByName(children, searchPhrase) };
    }
    return { ...currentFolderState };
  },
);

export const getAllFilesAndFoldersState = createSelector(getFileManagerState, (state: FileManagerModuleState) => state.allFilesAndFolders);
export const getFileOperationErrorState = createSelector(getFileManagerState, (state: FileManagerModuleState) => state.fileOperationErrorState);

export const getRecentFolders = createSelector(getFileManagerState, getSearchPhrase, (state, searchPhrase) =>
  filterFilesByName(state.recentFilesState.recent.recentFolders, searchPhrase),
);
export const getRecentFiles = createSelector(getFileManagerState, getSearchPhrase, (state, searchPhrase) =>
  filterFilesByName(state.recentFilesState.recent.recentFiles, searchPhrase),
);

export const getUsersWithFolders = createSelector(getFileManagerState, (state: FileManagerModuleState) => state.organizationUsers);
export const getSelectedUser = createSelector(getFileManagerState, (state: FileManagerModuleState) => state.selectedUser);

export const getUsersSelectItems = createSelector(getUsersWithFolders, (users: IUserProfile[]) =>
  users.map((user) => toSelectItem(user.uid, `${user.email} - ${user.authPlatform}`)),
);

export const filterFilesByName = (files: IFile[], phrase: string): IFile[] =>
  files.filter((file) => file.Name.toLowerCase().includes(phrase.toLowerCase()));

export const getFilesLoading = createSelector(getFileManagerState, (state: FileManagerModuleState) => state.loading);
