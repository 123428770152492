<div class="left-nav-container" [ngClass]="{'open': isSideNavExpanded, 'home-page-mode': homePageMode}"
     (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()">

  <app-left-nav-app-logo class="logo-wrapper" [isPinned]="isSideNavPinned" [isHomepage]="homePageMode"></app-left-nav-app-logo>
  <div class="left-nav">
    <div class="left-nav-links">
      <app-left-nav-item
        #homeMenuItem
        [isSideNavExpanded]="isSideNavExpanded"
        [element]="homeButtonItem"
        [isCalculationActive]="isCalculationActive"
        [isDisabledWhenCalculationActive]="DisabledWhenCalculationActiveMode.ElectronOnly"
      ></app-left-nav-item>

      <div class="link-buttons-container">
        <app-left-nav-item
          *ngIf="!homePageMode && showModuleSwitcher"
          [element]="moduleSelectionItem"
          [isRouterLinkDisabled]="true"
          [isSideNavExpanded]="isSideNavExpanded"
          (navClick)="runCalculationService.moduleSelectionClick()"
          [isCalculationActive]="isCalculationActive"
          [isDisabledWhenCalculationActive]="DisabledWhenCalculationActiveMode.ElectronOnly"
        ></app-left-nav-item>
        <div class="scrollable" #scrollableContainer>
          <div class="scrollable-shadow" [class.open]="isSideNavExpanded"></div>
          <div class="scrollable-buttons-container">
            <!--regular items-->
            <app-left-nav-item
              #navMenuItem
              [isSideNavExpanded]="isSideNavExpanded"
              [selectedUri]="selectedUri"
              [isSideNavPinned]="isSideNavPinned"
              [element]="$any(menuElement)"
              [initOpenElement]="isInitHover(menuElement)"
              (mouseenter)="onHoverNavItem(menuElement)"
              (reordered)="onReordered($event)"
              id="{{'nav-element-' + menuElement.label}}"
              *ngFor="let menuElement of menuData?.regular; trackBy: trackByFn"
              (activeMenuItemChanged)="activeMenuItemChanged.emit($event); initialHoverItem = null"
            ></app-left-nav-item>

            <div class="white-section" *ngIf="!homePageMode && menuData?.results?.length"
                 [ngClass]="{'open' : isSideNavExpanded}">
              <ng-template ngFor let-menuElement let-i="index" [ngForOf]="menuData?.results" [ngForTrackBy]="trackByFn">
                <ng-container [ngSwitch]="menuElement.type">
                  <app-left-nav-item
                    *ngSwitchCase="'NavElement'"
                    #navMenuItem
                    [isSideNavExpanded]="isSideNavExpanded"
                    [selectedUri]="selectedUri"
                    [isSideNavPinned]="isSideNavPinned"
                    [element]="$any(menuElement)"
                    [initOpenElement]="isInitHover(menuElement)"
                    [isResultItem]="true"
                    (mouseenter)="onHoverNavItem(menuElement)"
                    (reordered)="onReordered($event)"
                    id="{{'nav-element-' + menuElement.label}}"
                    (activeMenuItemChanged)="activeMenuItemChanged.emit($event); initialHoverItem = null"
                  ></app-left-nav-item>
                  <app-start-stop-button
                    *ngSwitchCase="'StartStopButton'"
                    (mouseenter)="onHoverNavItem(menuElement)"
                    [isSideNavExpanded]="isSideNavExpanded"
                  ></app-start-stop-button>
                </ng-container>
              </ng-template>
            </div >
          </div>
          <div class="white-fill" *ngIf="!homePageMode"></div>
          <div class="logo-footer">
            <app-left-nav-df-logo [isExpanded]="isSideNavExpanded" [isHomePageMode]="homePageMode"></app-left-nav-df-logo>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="open-close-btn" (click)="togglePinSideNav()" [class.active]="isSideNavPinned || homePageMode" *ngIf="!isAdminPanel">
    <span class="icon-sidemenu-pinned"></span>
  </div>
</div>


