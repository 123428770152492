<div class="dialog-header" *ngIf="title">
  <h4>{{ title }}</h4>

  <app-modal-help-button
          *ngIf="showHelpBtn"
          [dialogHasScrollbar]="dialogHasScrollbar"
          (helpClick)="helpClick.emit($event.isHelpOpen)"
  ></app-modal-help-button>
</div>
<div class="dialog-content" [attr.data-cy]="dataCy" [class.scrollable-content]="scrollableContent">
  <ng-content></ng-content>
</div>
<div class="dialog-footer flex-space-between">
  <div *ngFor="let buttons of buttonGroups">
    <ng-container *ngFor="let button of buttons; let i = index">
      <app-button
              *ngIf="button.show"
              [buttonType]="button.type"
              [dataCy]="button.dataCy || ''"
              (buttonClick)="button.action()"
              [disabled]="button.disabled"
              [class.disabled]="button.disabled"
              [isUiLockable]="button.isUiLockable ?? false"
              [class.no-left-margin]="i === 0"
              [text]="button.text"
      >
      </app-button>
    </ng-container>

    <span *ngIf="!buttons.length"></span>
  </div>
</div>
