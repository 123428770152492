import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonType } from '../../../shared/components/button/button.base.component';

export const CY_BUTTON_OK = 'button-ok';
export const CY_FOLDER_OK = 'new-folder-ok';
export const CY_NEW_FILE_OK = 'new-file-ok';

type ButtonPosition = 'right' | 'left';
export interface ModalButtonConfig {
  text: string;
  action: () => Promise<void> | void;
  dataCy?: string;
  type: ButtonType;
  show: boolean;
  disabled: boolean;
  id?: string;
  isUiLockable?: boolean;
  position: ButtonPosition;
}

export const defaultModalCancelButton = (
  action: () => Promise<void> | void,
  text = 'Cancel',
  dataCy = 'prompt-modal-reject',
  position: ButtonPosition = 'right',
): ModalButtonConfig => ({
  text,
  action,
  dataCy,
  type: 'cancel',
  show: true,
  disabled: false,
  position,
});
export const defaultModalConfirmButton = (
  action: () => Promise<void> | void,
  text = 'Ok',
  dataCy = 'prompt-modal-confirm',
  disabled = false,
  position: ButtonPosition = 'right',
): ModalButtonConfig => ({
  text,
  action,
  dataCy,
  type: 'primary',
  show: true,
  disabled,
  position,
});

export const modalButton = (
  text: string,
  action: () => Promise<void> | void,
  dataCy = '',
  type: ButtonType = 'primary',
  show = true,
  disabled = false,
  id?: string,
  isUiLockable = false,
  position: ButtonPosition = 'right',
): ModalButtonConfig => ({
  text,
  action,
  dataCy,
  type,
  show,
  disabled,
  id,
  isUiLockable,
  position: position != null ? position : 'right',
});

@Component({
  selector: 'app-generic-modal',
  templateUrl: './generic-modal.component.html',
  styleUrls: ['./generic-modal.component.scss'],
})
export class GenericModalComponent {
  @Input() public title: string | undefined;
  @Input() public dataCy: string | undefined;
  @Input() public showHelpBtn: boolean | undefined;
  @Input() public dialogHasScrollbar = false;
  @Input() public scrollableContent = false;
  @Input() public set modalButtonsConfigs(buttonsConfigs: ModalButtonConfig[]) {
    this.buttonGroups = [
      buttonsConfigs.filter((btn) => btn.position === 'left'),
      buttonsConfigs.filter((btn) => btn.position == null || btn.position === 'right'),
    ];
  }
  @Output() public helpClick = new EventEmitter<boolean>();

  public buttonGroups: ModalButtonConfig[][] = [];
  constructor(public ref: ChangeDetectorRef) {}
}
