import { IReportTableColumnConfig } from '../common-report-data-helpers';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { ColumnType } from '../../../../../components/grid/grid.interfaces';
import { SummaryDto } from '@dunefront/common/dto/summary.dto';

export const summaryPackEfficienciesProperties = (isOpenHole: boolean): IReportTableColumnConfig<SummaryDto>[] => {
  const openHoleProps: IReportTableColumnConfig<SummaryDto>[] = [
    {
      colId: 'MaxPackHeightRatio',
      headerText: 'Max Alpha Height',
      unitSystem: UnitSystem.None,
      type: ColumnType.number,
      overrideUnitSymbol: '%',
    },
    {
      colId: 'MaxPackHeight',
      headerText: 'Max Alpha Height',
      unitSystem: UnitSystem.Short_Length,
      type: ColumnType.number,
    },
  ];

  const nonOpenHoleProps: IReportTableColumnConfig<SummaryDto>[] = [
    {
      colId: 'FinalPerforationPackPercentage',
      headerText: 'Perf',
      unitSystem: UnitSystem.None,
      type: ColumnType.number,
      overrideUnitSymbol: '%',
    },
    {
      colId: 'PerfLinearGravelMass',
      headerText: 'Perf',
      unitSystem: UnitSystem.Linear_Mass,
      type: ColumnType.number,
    },
  ];

  return [
    {
      colId: 'FinalOverallPackPercentage',
      headerText: 'Well',
      unitSystem: UnitSystem.None,
      type: ColumnType.number,
      overrideUnitSymbol: '%',
    },
    {
      colId: 'FinalScreenPackPercentage',
      headerText: 'Screen',
      unitSystem: UnitSystem.None,
      type: ColumnType.number,
      overrideUnitSymbol: '%',
    },
    ...(isOpenHole ? openHoleProps : nonOpenHoleProps),
  ];
};
export const summaryGravelBelowPackerProperties: IReportTableColumnConfig<SummaryDto>[] = [
  {
    colId: 'BlankGravelMass',
    headerText: 'Blank',
    unitSystem: UnitSystem.Mass,
    type: ColumnType.number,
  },
  {
    colId: 'ScreenGravelMass',
    headerText: 'Screen',
    unitSystem: UnitSystem.Mass,
    type: ColumnType.number,
  },
  {
    colId: 'PerfGravelMass',
    headerText: 'Perf',
    unitSystem: UnitSystem.Mass,
    type: ColumnType.number,
  },
];

export const summaryMaxTotalECDsProperties: IReportTableColumnConfig<SummaryDto>[] = [
  {
    colId: 'MaxHeelECD',
    headerText: 'Heel',
    unitSystem: UnitSystem.Liquid_Density,
    type: ColumnType.number,
    decimalPlaces: 2,
  },
  {
    colId: 'MaxToeECD',
    headerText: 'Toe',
    unitSystem: UnitSystem.Liquid_Density,
    type: ColumnType.number,
    decimalPlaces: 2,
  },
  {
    colId: 'MaxPackerWorkstringECD',
    headerText: 'Packer (workstring)',
    unitSystem: UnitSystem.Liquid_Density,
    type: ColumnType.number,
    decimalPlaces: 2,
  },
  {
    colId: 'MaxPackerAnnulusECD',
    headerText: 'Packer (annulus)',
    unitSystem: UnitSystem.Liquid_Density,
    type: ColumnType.number,
    decimalPlaces: 2,
  },
];

export const summaryMaxTotalPressureProperties = (isFluidPro: boolean): IReportTableColumnConfig<SummaryDto>[] => {
  const fluidProFields: IReportTableColumnConfig<SummaryDto>[] = [
    {
      colId: 'MaxPackerWorkstringPressure',
      headerText: 'Packer (workstring)',
      unitSystem: UnitSystem.Pressure,
      type: ColumnType.number,
      decimalPlaces: 2,
    },
    {
      colId: 'MaxPackerAnnulusPressure',
      headerText: 'Packer (annulus)',
      unitSystem: UnitSystem.Pressure,
      type: ColumnType.number,
      decimalPlaces: 2,
    },
  ];

  return [
    {
      colId: 'MaxSurfacePressure',
      headerText: 'Surface',
      unitSystem: UnitSystem.Pressure,
      type: ColumnType.number,
    },
    {
      colId: 'MaxHeelPressure',
      headerText: 'Heel',
      unitSystem: UnitSystem.Pressure,
      type: ColumnType.number,
    },
    {
      colId: 'MaxToePressure',
      headerText: 'Toe',
      unitSystem: UnitSystem.Pressure,
      type: ColumnType.number,
    },
    ...(isFluidPro ? fluidProFields : []),
  ];
};

export const summaryFinalFracDataProperties: IReportTableColumnConfig<SummaryDto>[] = [
  {
    colId: 'FracNetPressure',
    headerText: 'Net Pressure',
    unitSystem: UnitSystem.Pressure,
    type: ColumnType.number,
  },
  {
    colId: 'FracFluidEfficiency',
    headerText: 'Fluid Efficiency',
    unitSystem: UnitSystem.None,
    type: ColumnType.number,
    overrideUnitSymbol: '%',
  },
  {
    colId: 'FracPackEfficiency',
    headerText: 'Pack Efficiency',
    unitSystem: UnitSystem.None,
    type: ColumnType.number,
    overrideUnitSymbol: '%',
  },
];

export const summaryMaxFrictionPressureProperties = (isFluidPro = false): IReportTableColumnConfig<SummaryDto>[] => {
  // Shared fields
  const maxWorkstringFriction: IReportTableColumnConfig<SummaryDto> = {
    colId: 'MaxWorkstringFriction',
    headerText: 'Workstring',
    unitSystem: UnitSystem.Pressure,
    type: ColumnType.number,
  };

  const maxUpperAnnulusFriction: IReportTableColumnConfig<SummaryDto> = {
    colId: 'MaxUpperAnnulusFriction',
    headerText: 'Upper Annulus',
    unitSystem: UnitSystem.Pressure,
    type: ColumnType.number,
  };

  const maxWashPipeFriction: IReportTableColumnConfig<SummaryDto> = {
    colId: 'MaxWashpipeFriction',
    headerText: 'Washpipe',
    unitSystem: UnitSystem.Pressure,
    type: ColumnType.number,
  };
  // end shared fields

  if (isFluidPro) {
    return [
      maxWorkstringFriction,
      maxWashPipeFriction,
      maxUpperAnnulusFriction,
      {
        colId: 'MaxLowerAnnulusFriction',
        headerText: 'Lower Annulus',
        unitSystem: UnitSystem.Pressure,
        type: ColumnType.number,
      },
    ];
  }

  return [
    {
      colId: 'MaxTreatingFriction',
      headerText: 'Treating Line',
      unitSystem: UnitSystem.Pressure,
      type: ColumnType.number,
    },
    {
      colId: 'MaxAnnularLineFriction',
      headerText: 'Annular Line',
      unitSystem: UnitSystem.Pressure,
      type: ColumnType.number,
    },
    maxWorkstringFriction,
    maxUpperAnnulusFriction,
    {
      colId: 'MaxServiceToolDownFlowFriction',
      headerText: 'Service Tool Cross Over',
      unitSystem: UnitSystem.Pressure,
      type: ColumnType.number,
    },
    {
      colId: 'MaxServiceToolUpFlowFriction',
      headerText: 'Service Tool Return',
      unitSystem: UnitSystem.Pressure,
      type: ColumnType.number,
    },
    {
      colId: 'MaxBlankFriction',
      headerText: 'Blank',
      unitSystem: UnitSystem.Pressure,
      type: ColumnType.number,
    },
    {
      colId: 'MaxScreenFriction',
      headerText: 'Screen',
      unitSystem: UnitSystem.Pressure,
      type: ColumnType.number,
    },
    maxWashPipeFriction,
    {
      colId: 'MaxGaugeCarrierFriction',
      headerText: 'Gauge Carriers',
      unitSystem: UnitSystem.Pressure,
      type: ColumnType.number,
    },
    {
      colId: 'MaxShuntFriction',
      headerText: 'Shunt',
      unitSystem: UnitSystem.Pressure,
      type: ColumnType.number,
    },
    {
      colId: 'GravelPack',
      headerText: 'Gravel Pack',
      unitSystem: UnitSystem.Pressure,
      type: ColumnType.number,
    },
    {
      colId: 'MaxChokeFriction',
      headerText: 'Choke',
      unitSystem: UnitSystem.Pressure,
      type: ColumnType.number,
    },
  ];
};
