import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ModalService } from '../modal.service';
import { compareScenariosChangeConfigAction } from '../../../+store/scenario/scenario.actions';
import { getScenarioIdsWithResultsForCurrentModule } from '../../../+store/calculation-engine/calculation-engine-results.selectors';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { createSelector, Store } from '@ngrx/store';
import { defaultModalCancelButton, modalButton, ModalButtonConfig } from '../generic-modal/generic-modal.component';
import { getAllScenarios, getCurrentScenario } from '../../../+store/scenario/scenario.selectors';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { runMultipleSimulationsForCompareScenario } from '../../../+store/calculation-engine/calculation-engine.actions';
import { SelectionState, TreeSelectorListItem, UpdateTreeSelection } from '../../../shared/components/filterable-tree/filterable-tree.component';

export interface ICompareScenarioVM {
  treeConfig: { itemsOrder: number[] };
  treeItems: TreeSelectorListItem[];
  scenariosWithoutResults: number[];
  currentScenarioId: number;
}

export const getCompareScenariosVm = createSelector(
  getAllScenarios,
  getCurrentScenario,
  getScenarioIdsWithResultsForCurrentModule,
  (allScenarios, currentScenario, completedResults) => {
    const treeItems = allScenarios.map((s) => ({
      label: s.Name,
      value: s.Id,
      disabled: currentScenario?.Id === s.Id,
      hasResults: completedResults.includes(s.Id),
      items: [],
    }));
    const treeConfig = { itemsOrder: allScenarios.map((s) => s.Id) };
    const vm: ICompareScenarioVM = {
      treeConfig,
      treeItems,
      scenariosWithoutResults: treeItems.filter((s) => !s.hasResults).map((s) => s.value),
      currentScenarioId: currentScenario?.Id ?? 0,
    };
    return vm;
  },
);

export const getCompareTreeItems = createSelector(getCompareScenariosVm, (vm) => vm.treeItems);

export const getCompareTreeConfig = createSelector(getCompareScenariosVm, (vm) => vm.treeConfig);

export const getCompareTreeInitialSelection = createSelector(getCurrentScenario, (currentScenario) => {
  const selection: SelectionState = { GroupIds: [], ItemIds: [] };
  if (!currentScenario) {
    return selection;
  }
  selection.GroupIds.push(currentScenario.Id);
  if (currentScenario.CompareScenarioIds.length > 0) {
    const toAdd = currentScenario.CompareScenarioIds.filter((scenarioId) => !selection.GroupIds.includes(scenarioId));
    selection.GroupIds = [...selection.GroupIds, ...toAdd];
  }
  return selection;
});

@Component({
  selector: 'app-compare-scenario',
  templateUrl: './compare-scenario.component.html',
  styleUrls: ['./compare-scenario.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompareScenarioComponent implements OnInit {
  public modalTitle = 'Compare Scenarios';
  public modalButtonsConfigs: ModalButtonConfig[] = [
    modalButton('Clear All', (): Promise<void> => this.clearSelection(), 'compare-scenario-clear-all'),
    defaultModalCancelButton(() => this.cancelClicked(), 'Cancel', 'compare-scenario-cancel-btn'),
    modalButton('Compare', (): Promise<void> => this.compareClicked(), 'compare-scenario-compare-btn'),
  ];

  public filter$ = new BehaviorSubject<string>('');
  public items$ = this.store.select(getCompareTreeItems);
  public config$ = this.store.select(getCompareTreeConfig);
  public selection$ = new BehaviorSubject<SelectionState>({ GroupIds: [], ItemIds: [] });
  public state$ = this.store.select(getCompareScenariosVm);

  constructor(
    protected store: Store,
    protected cdRef: ChangeDetectorRef,
    protected modalService: ModalService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
  ) {}

  public async ngOnInit(): Promise<void> {
    const { GroupIds } = await firstValueFrom(this.store.select(getCompareTreeInitialSelection));
    this.selection$.next({ GroupIds, ItemIds: [] });
  }

  public async cancelClicked(): Promise<void> {
    this.ref.close();
  }

  public async compareClicked(): Promise<void> {
    const state = await firstValueFrom(this.state$);
    const selected = await firstValueFrom(this.selection$);
    const selectedScenarioIds = selected.GroupIds;
    if (selectedScenarioIds.length > 10) {
      await this.modalService.showAlert('You can compare a maximum of 10 scenarios.', 'Information');
      return;
    }

    if (selectedScenarioIds.length < 2) {
      await this.modalService.showAlert('Select at least one other scenario for comparison.', 'Information');
      return;
    }

    const scenariosWithoutResults = state.scenariosWithoutResults.filter((scenarioToRun) => selectedScenarioIds.includes(scenarioToRun));
    if (scenariosWithoutResults.length > 0) {
      this.store.dispatch(runMultipleSimulationsForCompareScenario({ scenarioIds: scenariosWithoutResults }));
    }

    const scenarioIds = selectedScenarioIds.filter((scenarioId) => scenarioId !== state.currentScenarioId);
    this.store.dispatch(
      compareScenariosChangeConfigAction({
        batchAction: this.config.data.batchAction,
        scenarioIds,
      }),
    );
    await this.cancelClicked();
  }

  public async updateSelectedIds(selection: UpdateTreeSelection): Promise<void> {
    const { GroupIds } = selection.selectionState;
    const state = await firstValueFrom(this.state$);
    const currentScenarioId = state.currentScenarioId;
    if (!GroupIds.includes(currentScenarioId)) {
      GroupIds.push(currentScenarioId);
    }
    this.selection$.next({ GroupIds, ItemIds: [] });
  }

  public async clearSelection(): Promise<void> {
    const state = await firstValueFrom(this.state$);
    this.selection$.next({ GroupIds: [state.currentScenarioId], ItemIds: [] });
  }
}
