import { Router } from '@angular/router';
import { IFile } from '@dunefront/common/dto/file.dto';
import { Dictionary } from 'lodash';
import { LicenseFeature } from '@dunefront/common/modules/licensing/licensing.interfaces';
import { toBase64 } from 'js-base64';
import { RouteModuleFile, RouteModuleHome, RouteModuleHomePersonal } from '../../pages/home/home-page-routes-names';
import { RouteModuleFileManager, RouteModuleFileManagerDownload } from '@dunefront/common/common/routes-names/file-manager-routes-names';
import {
  RouteModuleAdmin,
  RouteModuleAdminFileManagement,
  RouteModuleAdminUser,
} from '@dunefront/common/common/routes-names/admin-routes-names';
import { getBackendToClientConfig } from '@dunefront/common/backend-to-client-config';
import { IUserProfile } from '@dunefront/common/modules/auth/auth.interfaces';

export class FileManagerHelper {
  public static cache: Dictionary<any> = {};

  public static async navigateToFile(router: Router, file: IFile, licenseFeature?: LicenseFeature): Promise<void> {
    await router.navigate([this.getEncodedUrlPath(file)], {
      queryParams: licenseFeature != null ? { licenseFeature } : {},
      replaceUrl: true,
      skipLocationChange: false,
    });
  }

  public static async navigateToFolder(router: Router, file: IFile): Promise<void> {
    const folderPath = file.Folder.map((folder) => encodeURIComponent(folder)).join('|');
    await router.navigate([`/${RouteModuleHome}/${file.Repository}/${folderPath}`]);
  }

  public static async switchFile(router: Router, target: IFile): Promise<void> {
    // change only file name and navigate to same path
    const routeArr = router.routerState.snapshot.url.split('/').slice(3);
    const newRoute = [this.getEncodedUrlPath(target), ...routeArr].join('/');

    await router.navigate([newRoute]);
  }

  public static async navigateHome(router: Router): Promise<void> {
    await router.navigate(['/home']);
  }

  public static async navigateAdminUserFileManagement(router: Router): Promise<void> {
    await router.navigate([`/${RouteModuleAdmin}/${RouteModuleAdminFileManagement}/${RouteModuleAdminUser}`]);
  }

  public static navigatePersonalFiles(router: Router, folder: IFile, encodedPath?: string): void {
    if ((folder.Repository !== 'personal' && folder.Repository !== 'shared') || router.url === `/${RouteModuleHome}`) {
      const uri = encodedPath
        ? `/${RouteModuleHome}/${RouteModuleHomePersonal}/${encodedPath}`
        : `/${RouteModuleHome}/${RouteModuleHomePersonal}`;
      router.navigate([uri]).then();
    }
  }

  public static getEncodedUrlPath(file: IFile): string {
    return `/${RouteModuleFile}/${this.getEncodedUrlFilePart(file)}`;
  }

  public static getEncodedUrlFilePart(file: IFile): string {
    if (file.Repository === 'electron') {
      return toBase64(file.Name, true);
    } else {
      let encodedPath = '';
      for (const folder of file.Folder) {
        encodedPath += encodeURIComponent(folder) + '|';
      }
      encodedPath += encodeURIComponent(file.Name);
      return encodedPath;
    }
  }

  public static downloadFile(file: IFile, token: string, sessionId: string, selectedUser: IUserProfile | null): void {
    window.open(FileManagerHelper.getDownloadUrl(file, token, sessionId, selectedUser));
  }

  public static openUrl(file: IFile): void {
    window.open(`${RouteModuleFile}://${file.Name}`);
  }

  public static getDownloadUrl(file: IFile, token: string, sessionId: string, selectedUser: IUserProfile | null = null): string {
    let url =
      getBackendToClientConfig().ServerBaseUrl +
      `/${RouteModuleFileManager}/${RouteModuleFileManagerDownload}/` +
      toBase64(JSON.stringify(file)) +
      '?jwt=' +
      token +
      '&ppSessionId=' +
      sessionId;

    if (selectedUser != null) {
      url += '&selectedUser=' + toBase64(JSON.stringify(selectedUser));
    }

    return url;
  }

  public static findFolder(pathArray: string[], currentFolder: IFile | undefined): IFile | undefined {
    if (pathArray.length === 0) {
      return currentFolder;
    }
    const reqFolder = currentFolder?.Children?.find((child: IFile) => child.FileType === 'folder' && child.Name === pathArray[0]);
    return reqFolder && pathArray.length > 1 ? this.findFolder(pathArray.slice(1, pathArray.length), reqFolder) : reqFolder;
  }

  public static getAllChildren(entry: IFile[]): IFile[] {
    return entry.flatMap((entry: IFile) => [entry, ...FileManagerHelper.getAllChildren(entry.Children || [])]).flat();
  }
}
