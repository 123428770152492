<app-generic-modal [title]="modalTitle" [modalButtonsConfigs]="modalButtonsConfigs" [scrollableContent]="true">
  @if ((state$ | async) && (selection$ | async)) {
    <app-filterable-tree
      (updateSelectedIds)="updateSelectedIds($event)"
      [config$]="config$" [filter$]="filter$" [items$]="items$" [selection$]="selection$"
      [reorderAllowed]="false" [showResults]="true" class="equal-indent"
      dataCy="compare-scenario"></app-filterable-tree>
    <div class="content-footer"> Simulations for scenarios without results will be run automatically before comparing
    </div>
  }
</app-generic-modal>
