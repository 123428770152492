<app-button
  class="big-button reporting-tools-optimize-btn"
  [isToggled]="chartMenuProps.isOptimizeChartActive"
  text="Optimize"
  textIcon="icon-computing-gear"
  (buttonClick)="optimizeChart(!chartMenuProps.isOptimizeChartActive)"
  [isUiLockable]="false"
  [disabled]="!chartMenuProps.isOptimizeChartEnabled"
  [advancedButtonContextMenuItems]="chartMenuProps.isOptimizeChartActive ? optimizeAdvancedButtonMenuItemsWhenEnabled : optimizeAdvancedButtonMenuItemsWhenDisabled"
  dataCy="reporting-tools-optimize-btn"
  [isAdvancedButton]="true"
  [advancedButtonWidth]="'12rem'"
></app-button>
