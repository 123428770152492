import { ChangeDetectorRef, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { editChartPropsAction } from '../../../+store/ui/ui.actions';
import { ModalService } from '../../../common-modules/modals/modal.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ReportingTabDto } from '@dunefront/common/dto/reporting-tab.dto';
import {
  defaultModalCancelButton,
  defaultModalConfirmButton,
  ModalButtonConfig
} from '../../../common-modules/modals/generic-modal/generic-modal.component';
import { areStringsTheSame } from '@dunefront/common/common/helpers';

const CY_BUTTON_OK = 'rename-reporting-tab-button-ok';
const reservedTabsNames = ['Reporting'];

@Component({
  selector: 'app-rename-reporting-tab',
  template: `
    <app-generic-modal [title]="'Edit Tab Title'" [modalButtonsConfigs]="modalButtonsConfigs">
      <p>Enter text</p>
      <app-input
        class="grid-two-one"
        [isTextInput]="true"
        [value]="reportingTab.TabName"
        [isUiLockable]="false"
        [maxWidth]="300"
        [isStringComparisonStrict]="true"
        [triggerOnKeyPress]="true"
        align="left"
        dataCy="edit-reporting-tab-name"
        (primitiveValueChanged)="textValueChanged($event.value)"
        [autoFocus]="true"
      >
      </app-input>
      <div class="error error-text" data-cy="dialog-error">{{ errorMessage }}</div>
    </app-generic-modal>
  `
})
export class RenameReportingTabComponent {
  public reportingTab!: ReportingTabDto;
  public errorMessage: string | null = null;

  private readonly originalTabName = '';

  public modalButtonsConfigs: ModalButtonConfig[] = [
    defaultModalCancelButton((): void => this.cancelClicked(), 'Cancel', 'rename-reporting-tab-button-cancel'),
    defaultModalConfirmButton((): void => this.okClicked(), 'OK', CY_BUTTON_OK, this.errorMessage != null)
  ];

  constructor(
    protected store: Store,
    protected modalService: ModalService,
    protected cdRef: ChangeDetectorRef,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {
    if (config.data.reportingTab) {
      this.reportingTab = config.data.reportingTab;
      this.originalTabName = config.data.reportingTab.TabName;
    }
  }

  public textValueChanged(name: string): void {
    const isDuplicateOrReserved =
      this.config.data.tabsNames.find((tabName: string) => areStringsTheSame(tabName, name))
      || reservedTabsNames.find(resName => areStringsTheSame(resName, name));

    if (name.length < 1) {
      this.errorMessage = 'The tab name cannot be empty.';
    } else if (isDuplicateOrReserved && !areStringsTheSame(this.originalTabName, name)) {
      this.errorMessage = 'Another tab with this name already exists';
    } else {
      this.errorMessage = null;
      this.reportingTab = { ...this.reportingTab, TabName: name };
    }
    const okButton = this.modalButtonsConfigs.find((conf) => conf.dataCy === CY_BUTTON_OK);
    if (okButton) {
      okButton.disabled = this.errorMessage != null;
    }
  }

  public okClicked(): void {
    this.store.dispatch(editChartPropsAction({ reportingTab: this.reportingTab }));
    this.ref.close();
  }

  public cancelClicked(): void {
    this.ref.close();
  }
}
