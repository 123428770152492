<div class="grid-form-container no-padding">
  <label [class.warning]="isValueDiffThanDefault('ChartMarkerLineStyle')">Line
    Style</label>
  <app-select
    [source]="markerStyle"
    [sourceDefaults]="defaultMarkerStyle"
    [key]="'ChartMarkerLineStyle'"
    [items]="LineStyles"
    [isUiLockable]="false"
    [disabled]="disabled"
    [warning]="isValueDiffThanDefault('ChartMarkerLineStyle')"
    (valueChanged)="valueChanged.emit($event)"
  ></app-select>

  <label [class.warning]="isValueDiffThanDefault('ChartMarkerLineThickness')">Line
    Thickness</label>
  <app-select
    [source]="markerStyle"
    [sourceDefaults]="defaultMarkerStyle"
    [key]="'ChartMarkerLineThickness'"
    [items]="lineThicknessSelect"
    [isUiLockable]="false"
    [disableSorting]="true"
    [disabled]="disabled"
    [warning]="isValueDiffThanDefault('ChartMarkerLineThickness')"
    (valueChanged)="valueChanged.emit($event)"
  ></app-select>

  <label [class.warning]="isValueDiffThanDefault('ChartMarkerLineColor')">Line
    Color</label>
  <app-select
    [source]="markerStyle"
    [sourceDefaults]="defaultMarkerStyle"
    [key]="'ChartMarkerLineColor'"
    [items]="Colors"
    [colorPicker]="true"
    [isUiLockable]="false"
    [disabled]="disabled"
    [warning]="isValueDiffThanDefault('ChartMarkerLineColor')"
    (valueChanged)="valueChanged.emit($event)"
  ></app-select>

  <ng-container *ngIf="showVerticalAlignment">
    <label
      [class.warning]="isValueDiffThanDefault('ChartMarkerVerticalTextAlignment')">Vertical
      Text Alignment</label>
    <app-select
      [source]="markerStyle"
      [sourceDefaults]="defaultMarkerStyle"
      [key]="'ChartMarkerVerticalTextAlignment'"
      [items]="VerticalAlignments"
      [isUiLockable]="false"
      [disabled]="disabled"
      [warning]="isValueDiffThanDefault('ChartMarkerVerticalTextAlignment')"
      (valueChanged)="valueChanged.emit($event)"
      [disableSorting]="true"

    ></app-select>

    <app-form-input
      [source]="markerStyle"
      [sourceDefaults]="defaultMarkerStyle"
      [key]="'ChartMarkerVerticalTextIndentation'"
      appUnwrap
      label="Vertical Text Indentation"
      [isTextInput]="false"
      [unitType]="UnitType.None"
      [isUiLockable]="false"
      [warning]="!disabled && !isVerticalTextIndentationDisabled && isValueDiffThanDefault('ChartMarkerVerticalTextIndentation')"
      (valueChanged)="valueChanged.emit($event)"
      [disabled]="disabled || isVerticalTextIndentationDisabled"
    ></app-form-input>
  </ng-container>

  <ng-container *ngIf="showHorizontalAlignment">
    <label
      [class.warning]="isValueDiffThanDefault('ChartMarkerHorizontalTextAlignment')">Horizontal
      Text Alignment</label>
    <app-select
      [source]="markerStyle"
      [sourceDefaults]="defaultMarkerStyle"
      [key]="'ChartMarkerHorizontalTextAlignment'"
      [items]="HorizontalAlignments"
      [isUiLockable]="false"
      [disabled]="disabled"
      [warning]="isValueDiffThanDefault('ChartMarkerHorizontalTextAlignment')"
      (valueChanged)="valueChanged.emit($event)"
      [disableSorting]="true"
    ></app-select>

    <app-form-input
      [source]="markerStyle"
      [sourceDefaults]="defaultMarkerStyle"
      [key]="'ChartMarkerHorizontalTextIndentation'"
      appUnwrap
      [isUiLockable]="false"
      label="Horizontal Text Indentation"
      [isTextInput]="false"
      [unitType]="UnitType.None"
      (valueChanged)="valueChanged.emit($event)"
      [warning]="!disabled && !isHorizontalTextIndentationDisabled && isValueDiffThanDefault('ChartMarkerHorizontalTextIndentation')"
      [disabled]="disabled || isHorizontalTextIndentationDisabled"
    ></app-form-input>
  </ng-container>

  <app-font-style
    appUnwrap
    [source]="markerStyle"
    [sourceDefaults]="defaultMarkerStyle"
    [fontSizeKey]="'ChartMarkerFontSize'"
    [isBoldKey]="'IsChartMarkerFontBold'"
    [isItalicKey]="'IsChartMarkerFontItalic'"
    [fontColorKey]="'ChartMarkerFontColor'"
    [idPrefix]="'chart-marker'"
    [disabled]="disabled"
    (valueChanged)="valueChanged.emit($event)"
  ></app-font-style>
</div>
