import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { DbDependentComponent } from '../../../common-modules/db-connection/db-dependent.component';
import { TabMenuItem } from '../../../common-modules/tabbed-page/tabbed-page.component';
import {
  getIsReportPanelVisible,
  getSortedReportingTabsForCurrentRange
} from '../../../+store/reporting/reporting.selectors';
import { Router } from '@angular/router';
import * as uiActions from '../../../+store/ui/ui.actions';
import { ModalService } from '../../../common-modules/modals/modal.service';
import { RenameReportingTabComponent } from './rename-reporting-tab.component';
import { map } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { ReportingTabDto } from '@dunefront/common/dto/reporting-tab.dto';
import { IReorderEventArgs } from '../../../shared/components/left-nav/left-nav-item/left-nav-item.component';
import { reorderReportingTabAction } from '../../../+store/reporting/reporting.actions';
import { getIsTrendAnalysisRunning } from '../../../+store/calculation-engine/calculation-engine.selectors';

const reportingTabMenu: TabMenuItem = {
  name: 'Reporting',
  routerLink: 'tab/report',
};

@Component({
  selector: 'app-reporting',
  templateUrl: './reporting.component.html',
  styleUrls: ['./reporting.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportingComponent extends DbDependentComponent {
  public tabs$ = combineLatest([
    this.store.select(getSortedReportingTabsForCurrentRange),
    this.store.select(getIsReportPanelVisible),
    this.store.select(getIsTrendAnalysisRunning),
  ]).pipe(
    map(([tabs, isReportingReady, isTrendAnalysisRunning]): TabMenuItem[] => {
      if (!isReportingReady || isTrendAnalysisRunning) {
        return [];
      }

      const allTabsNames = tabs.map(t => t.TabName);

      return [reportingTabMenu, ...tabs.map((tab) => this.tabToTabMenuItem(tab, true, allTabsNames))];
    }),
  );

  constructor(
    store: Store,
    cdRef: ChangeDetectorRef,
    protected router: Router,
    private modalService: ModalService,
  ) {
    super(store, cdRef);

    store.dispatch(uiActions.setAppSectionAction({ section: 'reporting' }));
  }

  private tabToTabMenuItem = (tab: ReportingTabDto, isDraggable = false, tabsNames: string[]): TabMenuItem => ({
    name: tab.TabName,
    routerLink: 'tab/' + tab.Id,
    dbClick: (): void => {
      this.modalService.open(RenameReportingTabComponent, { reportingTab: tab, tabsNames: tabsNames }, 'scenario-create-modal', '400px');
    },
    isDraggable,
  });

  public onReordered(event: IReorderEventArgs): void {
    this.store.dispatch(reorderReportingTabAction({ toIndex: event.currentIndex - 1, fromIndex: event.prevIndex - 1 }));
  }
}
