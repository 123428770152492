<div class="home-container" [class.electron]="isElectron">
  <app-left-nav [homePageMode]="true" [menuData]="{regular: homeTabs, results: []}"></app-left-nav>

  <div class="home-content-wrapper" [class.shrink-left]="isSideNavPinned">
    <app-header [homePageMode]="true"></app-header>

    <div class="home-content">
      @if (isElectron()) {
        <app-sub-home-header [repository]="repository"></app-sub-home-header>
      } @else {
        <app-sub-home-header [repository]="repository" [folder]="folder"
                             (uploadTriggered)="uploadFile()"></app-sub-home-header>
      }
      <div class="home-page-content" *ngIf="isBackendConnected$ | async">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>

@if (isElectron()) {
  <app-upload [accept]="getAcceptedFileExtension()" *ngIf="isElectron()"></app-upload>
} @else {
  <app-upload
    [repository]="repository"
    [folder]="folder"
    [fileListInFolder]="getFileListInFolder()"
    [accept]="getAcceptedFileExtension()"
    (uploadFinished)="uploadFinished($event)"
  >
  </app-upload>
}


