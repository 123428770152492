<ng-container *ngIf="frictionCalculatorData && frictionCalculatorResult">
  <app-page-panel header="Flow Data" (click)="onHelpChange('calculators', 'friction-flow-data')" class="flow-data-panel">
    <div class="grid-form-container cols-6" appEqualizeSymbols>
      <app-fluid-selector
        [source]="frictionCalculatorData"
        [key]="'FluidId'"
        label="Fluid"
        appUnwrap

        [errorMessage]="frictionCalculatorData.error.FluidId"
        (valueChanged)="submitCalcData($event)"
        dataCy="friction-calculator-fluid"
        [showSymbol]="true"
      >
      </app-fluid-selector>
      <app-gravel-selector
        [source]="frictionCalculatorData"
        [key]="'GravelId'"
        label="Gravel"
        appUnwrap
        [errorMessage]="frictionCalculatorData.error.GravelId"
        (valueChanged)="submitCalcData($event)"
        dataCy="friction-calculator-gravel"
        [showSymbol]="true"
      >
      </app-gravel-selector>
      <div class="grid-two-one"></div>
      <app-form-input
        [disabled]="!frictionCalculatorData.GravelId || frictionCalculatorData.GravelId === 0"
        label="Concentration"
        [source]="frictionCalculatorData"
        [key]="'Concentration'"
        [decimalPlaces]="2"
        appUnwrap
        [unitType]="UnitType.Solid_Concentration"
        (valueChanged)="submitCalcData($event)"
        dataCy="friction-calculator-concentration"
      >
      </app-form-input>
      <app-form-input
        label="Max Rate"
        [source]="frictionCalculatorData"
        [key]="'MaxRate'"
        [decimalPlaces]="2"
        appUnwrap
        [unitType]="UnitType.Rate"
        (valueChanged)="submitCalcData($event)"
        dataCy="friction-calculator-max-rate"
      >
      </app-form-input>
      <app-form-input
        label="Roughness"
        [source]="frictionCalculatorData"
        [disabled]="
          frictionCalculatorData.FlowPathType === FlowPathType.Rectangular_Shunt ||
          frictionCalculatorData.FlowPathType === FlowPathType.Round_Shunt
        "
        [key]="'Roughness'"
        [decimalPlaces]="4"
        appUnwrap
        [cssClass]="'pr-1'"
        [unitType]="UnitType.Diameter"
        (valueChanged)="submitCalcData($event)"
        dataCy="friction-calculator-roughness"
      >
      </app-form-input>
      <app-form-input
        label="Temperature"
        [source]="frictionCalculatorData"
        [key]="'Temperature'"
        [decimalPlaces]="2"
        appUnwrap
        [unitType]="UnitType.Temperature"
        (valueChanged)="submitCalcData($event)"
        dataCy="friction-calculator-temperature"
      >
      </app-form-input>
      <app-form-input
        label="Rate Step"
        [source]="frictionCalculatorData"
        [key]="'RateStep'"
        [decimalPlaces]="2"
        appUnwrap
        [unitType]="UnitType.Rate"
        (valueChanged)="submitCalcData($event)"
        dataCy="friction-calculator-rate-step"
      >
      </app-form-input>
      <app-form-input
        label="Eccentricity"
        [source]="frictionCalculatorData"
        [disabled]="frictionCalculatorData.FlowPathType !== FlowPathType.Annulus"
        [key]="'Eccentricity'"
        [decimalPlaces]="2"
        [minWidth]="150"
        [forceShowSymbol]="true"
        appUnwrap
        [cssClass]="'pr-1'"
        [unitType]="UnitType.None"
        (valueChanged)="submitCalcData($event)"
        dataCy="friction-calculator-eccentricity"
      >
      </app-form-input>
      <app-check-box
        [source]="frictionCalculatorData"
        [key]="'UseDegradationFactor'"
        label="Degradation Factor"
        [labelWidth]="130"
        (valueChanged)="submitCalcData($event)"
        dataCy="friction-calculator-use-degradation-factor"
      >
      </app-check-box>
      <app-form-input
        appUnwrap
        [disabled]="true"
        [readOnlyValue]="degradationFactor"
        [decimalPlaces]="4"
        [forceShowSymbol]="true"
        [unitType]="UnitType.None"
        dataCy="friction-calculator-fluid-degradation-factor"
      >
      </app-form-input>

      <app-check-box
        [source]="frictionCalculatorData"
        [key]="'UseShuntVESFrictionCorrelations'"
        [disabled]="
          frictionCalculatorData.FlowPathType === FlowPathType.Pipe || frictionCalculatorData.FlowPathType === FlowPathType.Annulus
        "
        label="Use Shunt Surfactant Gel Friction Correlations"
        class="grid-two-one"
        (valueChanged)="submitCalcData($event)"
        dataCy="friction-calculator-use-shunt"
      >
      </app-check-box>
    </div>
  </app-page-panel>
  <div class="chart-grid-container">
    <app-page-panel
      header="Flow Path Dimensions"
      (click)="onHelpChange('calculators', 'friction-flow-path-dimensions')"
      class="flow-path-panel"
    >

        <app-radio-group
          [items]="flowPathDimensionsValues"
          [source]="frictionCalculatorData"
          [key]="'FlowPathType'"
          class="grid-two-one"
          layout="vertical"
          id="FlowPathDimensions"
          (valueChanged)="submitCalcData($event)"
          dataCy="friction-calculator-flow-path-dimensions"
        >
        </app-radio-group>

      <div class="grid-form-container cols-2 flow-path-dimensions-inputs-panel" appEqualizeSymbols>
        <app-form-input
          label="Length"
          [source]="frictionCalculatorData"
          [key]="'Length'"
          [decimalPlaces]="2"
          [unitType]="UnitType.Long_Length"
          (valueChanged)="submitCalcData($event)"
          appUnwrap
          dataCy="friction-calculator-length"
        >
        </app-form-input>
        <app-form-input
          [hidden]="frictionCalculatorData.FlowPathType === FlowPathType.Rectangular_Shunt"
          [label]="MinDiameterLabel"
          [source]="frictionCalculatorData"
          [key]="'MinDiameter'"
          [decimalPlaces]="3"
          [unitType]="UnitType.Diameter"
          (valueChanged)="submitCalcData($event)"
          appUnwrap
          dataCy="friction-calculator-min-diameter"
        >
        </app-form-input>
        <app-form-input
          [hidden]="frictionCalculatorData.FlowPathType !== FlowPathType.Annulus"
          [label]="MaxDiameterLabel"
          [source]="frictionCalculatorData"
          [key]="'MaxDiameter'"
          [decimalPlaces]="3"
          [unitType]="UnitType.Diameter"
          (valueChanged)="submitCalcData($event)"
          appUnwrap
          dataCy="friction-calculator-max-diameter"
        >
        </app-form-input>
        <app-form-input
          [hidden]="frictionCalculatorData.FlowPathType !== FlowPathType.Rectangular_Shunt"
          label="Height"
          [source]="frictionCalculatorData"
          [key]="'Height'"
          [decimalPlaces]="3"
          [unitType]="UnitType.Short_Length"
          (valueChanged)="submitCalcData($event)"
          appUnwrap
          dataCy="friction-calculator-height"
        >
        </app-form-input>
        <app-form-input
          [hidden]="frictionCalculatorData.FlowPathType !== FlowPathType.Rectangular_Shunt"
          label="Width"
          [source]="frictionCalculatorData"
          [key]="'Width'"
          [decimalPlaces]="3"
          [unitType]="UnitType.Short_Length"
          (valueChanged)="submitCalcData($event)"
          appUnwrap
          dataCy="friction-calculator-width"
        >
        </app-form-input>
      </div>
    </app-page-panel>
    <app-page-panel

      header="Results"
      (click)="onHelpChange('calculators', 'friction-results')"
      class="results-panel"
      [panelHelpMode]="PanelHelpMode.CHART"
    >
      <div class="results-container">
        <div class="grid-form-container">
          <label>X-Axis Values</label>
          <app-radio-group
            [source]="frictionCalculatorData"
            [key]="'IsXAxisRate'"
            [items]="xAxisValues"
            [value]="frictionCalculatorData.IsXAxisRate"
            layout="horizontal"
            id="IsXAxisRate"
            [isUiLockable]="false"
            (valueChanged)="submitCalcData($event)"
            dataCy="friction-calculator-x-axis"
          >
          </app-radio-group>
        </div>
        <div class="chart-container">
          <app-friction-calculator-result-chart
            *ngIf="frictionCalculatorResult.length > 0; else defaultMessage"
            [displayedAxis]="frictionCalculatorData.IsXAxisRate"
            [frictionCalculatorResult]="frictionCalculatorResult"
          >
          </app-friction-calculator-result-chart>
          <ng-template #defaultMessage><p class="mt-1">Click the "Calculate" button to generate results.</p></ng-template>
        </div>
      </div>
    </app-page-panel>
  </div>
</ng-container>
