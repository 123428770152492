<ng-container
  *ngrxLet="{items: items$, filteredItems: filteredItems$, selection: selection$, config: config$, filter: filter$, groupedItems: groupedItems$} as vm">
  <div class="filters">
    <div class="filters-left">
      <p-checkbox
        [disabled]="isReorderMode"
        [binary]="true"
        [ngModel]="isEverythingVisibleChecked(vm.items, vm.selection)"
        (ngModelChange)="onToggleAll(vm.items, vm.selection, vm.config.itemsOrder, $event)"
        [attr.data-cy]="dataCy + '-toggle-all-checkbox'"
      ></p-checkbox>

      <app-input
        [maxWidth]="225"
        [width]="225"
        [showSymbol]="false"
        [align]="'left'"
        [value]="vm.filter"
        [disabled]="isReorderMode"
        [isTextInput]="true"
        [isUiLockable]="false"
        [placeholder]="'Search'"
        [triggerOnKeyPress]="true"
        (primitiveValueChanged)="filter$.next($event.value.toString())"
        [icon]="'icon-search'"
        [dataCy]="dataCy + '-filter-input'"
      ></app-input>
    </div>
    @if (reorderAllowed) {
      <app-button
        [dataCy]="dataCy + '-reorder-button'"
        [class.warning]="isReorderMode"
        [disabled]="vm.items.length <= 1 || vm.filter.length > 0"
        [isDeleteResultsEnabled]="false"
        (buttonClick)="toggleReorder()"
        text="Reorder"
      >
      </app-button>
    }
  </div>

  <p-listbox
    [multiple]="true"
    [checkbox]="true"
    [metaKeySelection]="false"
    [showToggleAll]="false"
    class="chart-selector-listbox"
    [listStyle]="{ 'max-height': '100%' }"
    cdkDropList
    (cdkDropListDropped)="onDrop($event, vm.items, vm.selection)"
    [options]="vm.filteredItems"
    [group]="true"
    [ngModel]="vm.selection.ItemIds"
    [class.reorder]="isReorderMode"
    (ngModelChange)="onItemSelection($event, vm.items, vm.selection, vm.config.itemsOrder)"
    [attr.data-cy]="dataCy"
  >

    <ng-template let-group pTemplate="group">
      <div class="group" cdkDrag
           cdkDragBoundary=".p-listbox-list"
           [cdkDragLockAxis]="'y'"
           [cdkDragDisabled]="!isReorderMode"
           [class.draggable]="isReorderMode"
           [attr.data-cy]="dataCy + '-group-checkbox-' + group.value"
           [class.disabled]="group.disabled"
      >
        <i class="icon-arrow-up-down reorder-icon" *ngIf="isReorderMode"></i>
        <p-checkbox [inputId]="group.value" *ngIf="!isReorderMode && canSelectGroups" [ngModel]="vm.selection.GroupIds"
                    [value]="group.value"
                    [disabled]="group.disabled"
                    (ngModelChange)="onGroupSelection(group.value, vm.selection, vm.config.itemsOrder, vm.groupedItems)"></p-checkbox>
        <label [for]="group.value" [class.disabled]="group.disabled"><span>{{ group.label }}</span>
          @if (showResults && !group.hasResults) {
            <span class="no-results">(no results)</span>
          }
        </label>

      </div>
    </ng-template>

    <ng-template let-item pTemplate="item">
      <span class="item-label"
            [attr.data-cy]="dataCy + '-item-checkbox-' + item.value">{{ item.label }}</span>
    </ng-template>
  </p-listbox>

</ng-container>
