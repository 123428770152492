<div class="file-list-file" [class.recent]="isRecentMode">
  <div class="file-item">
    <div class="icon-wrapper">
      <img [src]="iconPath" alt="PackPro File">
    </div>
    <div class="file-item-content">
      <div>
        <span [innerHTML]="getFolderPath(file, searchPhrase)"></span>
        @if (isFolder) {
            <span data-cy="file-name"
                  [innerHTML]="(file.Name || '/') | HighlightPhrasePipe: searchPhrase "></span>
        } @else {
            <span data-cy="file-name" [innerHTML]="file | fileDisplayName | HighlightPhrasePipe: searchPhrase"></span>
          @if (!isRecentMode && file.FileSize) {
            <span class="file-size"> - ( {{ file.FileSize | fileSize }} )</span>
          }
        }
        @if (isRecentMode && repository !== 'trash') {
          <div class="file-location" data-cy="file-location">
            {{ file | fileLocation }}
          </div>
        }
      </div>
    </div>
  </div>

  @if (!isReadOnly(file)) {
    @if (repository === 'trash') {
      <div class="trash-date">{{ file.TrashTimestamp | date: 'medium' }}</div>
    }
    @if (file.LastOpenTimestamp) {
      <div class="file-item-date">
        {{ file.LastOpenTimestamp | date: 'medium' }}
      </div>
    }
    @if (repository === 'backup' && file.LastChangedTime) {
      <div class="file-item-date">
        {{ file.LastChangedTime | date: 'medium' }}
      </div>
    }
    @if (showRowContextMenu) {
      <div class="file-button">
        <button class="context-menu-button" (click)="contextMenuClick.emit({file, event: $event})">&#8942;</button>
      </div>
    }
  } @else {
    <div class="file-item-date">
      {{ getReadOnlyFileDescription(file) }}
    </div>
    @if (showRowContextMenu) {
      <div class="file-button">
        <button class="context-menu-button disabled">&#8942;</button>
      </div>
    }
  }
</div>
